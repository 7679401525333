import React from 'react'

import { Footer, PostFooter } from './styles'
import { LargeContainer } from '../Container'

export default function _Footer () {
  return (
    <div>
      <Footer>
        <LargeContainer>
          <ul>
            <li>
              <b>Horário de Funcionamento:</b> 09:30 às 18:00
            </li>
            <li>
              <b>Endereço:</b> Rua dos Guajajaras, 870 - 13º Andar - Centro -
              BH - MG
            </li>
            <li>
              <b>CEP:</b> 30.180-106
            </li>
            <li>
              <b>Telefone:</b> <a href="tel:+553132228012">(31) 3222-8012</a>  
            </li>
            <li>
              <b>WhatsApp:</b> <a href="https://wa.me/05531971876936">(31) 9 7187-6936</a>
            </li>
            <li>
              <b>E-mail para Vendas:</b>{' '}
              <a href='mailto:vendas@lognature.com.br'>
                vendas@lognature.com.br
              </a>
            </li>
            <li>
              <b>E-mail para Assistência Técnica:</b>{' '}
              <a href='mailto:assistencia@lognature.com.br'>
                assistencia@lognature.com.br
              </a>
            </li>
            <li>
              <b>E-mail para Outros Assuntos:</b>{' '}
              <a href='mailto:contato@lognature.com.br'>
                contato@lognature.com.br
              </a>
            </li>
          </ul>
        </LargeContainer>
      </Footer>
      <PostFooter>
        <LargeContainer>
          <b>Importante:</b> Prezados Clientes, os produtos oferecidos pela Log
          Nature são exclusivamente destinados ao desenvolvimento da pesquisa
          biológica e ambiental. Desta forma, os mesmos não podem ser utilizados
          para a caça, perseguição, destruição ou apanha de espécimes da fauna
          silvestre (Decreto 6.514/08, art. 28). A utilização indevida dos
          equipamentos fere a norma legal, na qual o adquirente estará sujeito
          às penalidades legais pertinentes.
        </LargeContainer>
      </PostFooter>
    </div>
  )
}
