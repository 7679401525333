import React, { useState } from 'react'
import { Container } from '../../components/Container'
import { Paragraph, Header, Link } from '../../components/Text'
import Form from '../../components/Form'
import { SubmitButton, Button } from '../../components/Button'
import api from '../../services/api'
import { verifySerialNumber } from '../../helper/BusinessRules'

export default function VerifyWarrantyPage() {

  const [serialNumber, setSerialNumber] = useState('')
  const [pending, setPending] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    setPending(true)

    if (!verifySerialNumber) {
      alert(`Por favor, insira um número de série válido. (Por exemplo: B123456789 ou CI12345678900)`)
      setPending(false)
      return 
    }
    
    api.get(`/garantias/${serialNumber}`)
    .then(response => response.data)
    .then(response => {
      setPending(false)

      if (response.error) {
        alert(response.error)
        return
      }

      if (response.assistencia)
        alert('Laudo encontrado!')
      else if (response.notasFiscais && response.notasFiscais.length > 0) 
        alert('Garantia válida!')
      else
        alert('Garantia não encontrada.')

    })

  }

  return (
    <Container>
      <Header>
        verificar garantia
      </Header>
      <Paragraph>
        Para continuar, preencha o <b>número de série da câmera.</b>
      </Paragraph>
      <Form onSubmit={handleSubmit}>
        <input required type='text' minLength='10' maxLength='13' onChange={(e) => setSerialNumber(e.target.value)} placeholder='Número de série da câmera (BXXXXXXXXX ou CIXXXXXXXXXXX)'/>
        <Link to='/'>
          <Button style={{marginLeft: 0, marginBottom: '30px', float: 'left'}}>
            voltar
          </Button>
        </Link>
        <div style={{height: '100px'}}>
          <SubmitButton disabled={pending}>verificar</SubmitButton>
        </div>
      </Form>
    </Container>
  )
}