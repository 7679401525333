import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 874px;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    width: 95%
  }
`

export const LargeContainer = styled.div`
  width: 100%;
  max-width: 1076px;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    width: 95%
  }
`