import React from 'react'
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom'

import Header from './components/Header'

import Homepage from './pages/Homepage'
import SuccessPage from './pages/SuccessPage'
import Footer from './components/Footer'
import VerifyWarrantyPage from './pages/VerifyWarrantyPage'
import ConditionsPage from './pages/ConditionsPage'

export default function Routes({...props}) {

  return (
    <Router>
      <Header/>
      <Route exact path='/' component={Homepage}/>
      <Route exact path='/condicoes' component={ConditionsPage}/>
      <Route exact path='/verificar-garantia' component={VerifyWarrantyPage}/>
      <Route exact path='/sucesso' component={SuccessPage}/>
      <Redirect to='/'/>
      <Footer/>
    </Router>
  )
}