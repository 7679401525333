import React from 'react'
import { Container } from '../../components/Container'
import { SuccessHeader, Paragraph } from '../../components/Text'

export default function SuccessPage() {
  return (
    <Container>
      <SuccessHeader>
        solicitação enviada
      </SuccessHeader>
      <Paragraph>
        Obrigado(a), recebemos sua solicitação de assistência técnica. <br/>
        <span style={{color: '#57bf00', fontWeight: 'bold'}}>Em breve retornaremos!</span>
      </Paragraph>
      <Paragraph>
        Qualquer dúvida, gentileza entre em contato. <br/>
        Equipe Log Nature.
      </Paragraph>
      <br/>
    </Container>
  )
}