import styled from 'styled-components'

export const Footer = styled.div`
  background-color: #45576115;
  padding: 25px 0px;
  font-size: 1rem;

  li {
    line-height: 1.5rem
  }
`

export const PostFooter = styled.div`
  padding: 25px 0px;
  font-size: 1rem;
  line-height: 1.5rem
`