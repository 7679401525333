import styled from 'styled-components'

export const Contact = styled.div`
  height: 48px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ul {
    white-space: nowrap;
  }

  li {
    font-weight: bold;
    display: inline;
    white-space: nowrap;
    display: inline-block;
    margin-left: 25px;
  }

  @media only screen and (max-width: 600px) {
    li:nth-last-child(-n+2) { 
      display: none
    }
  }

`

export const Nav = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #45576115;
  padding: 30px 0px;

  * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h1 {
    font-size: 2rem;
    font-weight: bolder;
  }

  div a:first-child {
    width: 100%;
    max-width: 50%;
  }

  img {
    max-width: 200px;
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 1.5rem;
      text-align: right
    }
  }
`