import React from 'react'
import { Container } from '../../components/Container'
import { Header, Paragraph, Link } from '../../components/Text'
import { Button } from '../../components/Button'

export default function ConditionsPage({ history }) {
  

  return (
    <Container>
      <Header>
        condições da assistência técnica
      </Header>
      <Paragraph>
        Câmeras na garantia da LOG serão avaliadas e reparadas gratuitamente. Caso sua câmera não tenha conserto, enviaremos uma nova unidade! Esta garantia não cobre danos causados ​​por uso indevido, manuseio inadequado, instalação incorreta, submersão ou intempéries não especificadas no grau de proteção IPX-5, avarias provenientes de animais e/ou terceiros e manutenção fornecida por prestadores de serviço não autorizados pela Log Nature.
      </Paragraph>
      <Paragraph>
        Câmeras fora da garantia, porém adquiridas LOG ou em fornecedores autorizados, serão avaliadas gratuitamente.
      </Paragraph>
      <Paragraph>
        Para as câmeras compradas no exterior ou em fornecedores não autorizados será cobrada uma taxa de avaliação no valor de R$100,00 por câmera.
      </Paragraph>
      <Paragraph>
        Caso a câmera não tenha reparo após a garantia, os clientes que adquiriram as câmeras na LOG poderão participar do PROGRAMA DE RECICLAGEM, comprando uma nova câmera com 20% de desconto.
      </Paragraph>
      <Link to='/'>
        <Button style={{marginLeft: 0, marginBottom: '30px'}}>
          certo, entendi!
        </Button>
      </Link>
    </Container>
  )
}