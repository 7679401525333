import React from 'react'

import { Link } from 'react-router-dom'

import { Contact, Nav } from './styles'
import { LargeContainer } from '../Container'

import logo from '../../img/logo.svg'

export default function Header ({...props}) {
  return (
    <div>
        <Contact>
          <LargeContainer>
            <ul>
              <li>
                <a href="mailto:vendas@lognature.com.br">
                  vendas@lognature.com.br
                </a>
              </li>
              <li>
                +55 31 99281-5079
              </li>
              <li>
                +55 31 3222-8012
              </li>
              <li>
                9h30 às 18h
              </li>
            </ul>
          </LargeContainer>
        </Contact>
        <Nav>
          <LargeContainer>
            <Link to='https://lognature.com.br'>
              <img src={logo} alt='LOG Nature'></img>
            </Link>
            <Link to='/'>
              <h1>assistência técnica</h1>
            </Link>
          </LargeContainer>
        </Nav>
    </div>
  )
}