import styled from 'styled-components'
import { Link as link } from 'react-router-dom'

export const Header = styled.h1`
  font-size: 1.5rem;
  color: #455761;
  font-weight: bold;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
`

export const SuccessHeader = styled(Header)`
  color: #57bf00;
`

export const Paragraph = styled.p`
  line-height: 22px;
  color: #455761;
  margin-bottom: 15px;
  font-size: 1rem;
  text-align: justify;
`

export const Link = styled(link)`
  color: #57bf00;
`