import React from 'react';
import GlobalStyle from './styles'
import Routes from './Routes'

function App() {
  return (
    <div className="App">
      <GlobalStyle/>
      <Routes/>
    </div>
  );
}

export default App;
