module.exports = {
  verifySerialNumber(serial_number) {
    const regex1 = RegExp(/[Bb]{1}[0-9]{9}/)
    const regex2 = RegExp(/[Cc][Ii][0-9]{11}/)
    const regex3 = RegExp(/[Bb][Zz][0-9]{11}/)
  
    if (serial_number.length === 10)
      return regex1.test(serial_number)

    if (serial_number.length === 13)
      return regex2.test(serial_number) || regex3.test(serial_number)
  
    return false
  }
}