import styled from 'styled-components'

export const Button = styled.button`
  height: 3rem;
  background: none;
  border: 2px solid #455761;
  border-radius: 5px;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 0.8rem;
  padding: 15px;
  color: #455761;
  cursor: pointer;
  margin-left: 10px;

  transition: all .3s ease-in-out;

  :hover {
    background-color: #455761;
    color: #fff
  }
  
`

export const SubmitButton = styled(Button).attrs(props => ({
  type: 'submit'
}))`
  border: 2px solid #57bf00;
  color: #57bf00;

  :hover {
    background-color: #57bf00;
  }

  :disabled {
    background-color: #455761;
    cursor: not-allowed;
    border: 2px solid #455761;
    color: #fff;
  }
`