import styled from 'styled-components'

export const Form = styled.form`
  * {
    margin-bottom: 10px;
  }

  label {
    font-size: 1.5rem;
    text-align: right;
    display: block;
    opacity: 0.5;
    font-weight: bold;
  }

  input, textarea, select {
    display: block;
    width: 100%;
    border: 1px solid #455761;
    border-radius: 5px;
    height: 2.3rem;
    padding: 0 10px;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    font-weight: bold
  }

  input::placeholder, textarea::placeholder {
    font-weight: bold;
    opacity: 1
  }

  select {
    padding-left: 6px !important;
  }

  textarea {
    height: 9rem !important;
    padding-top: 10px;
    resize: none;
  }

  button {
    float: right
  }
`

export default Form