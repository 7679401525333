/* eslint-disable no-loop-func */
import React, { useState } from 'react'
import { Container } from '../../components/Container'
import { Header, Paragraph, Link } from '../../components/Text'
import Form from '../../components/Form'
import { Button, SubmitButton } from '../../components/Button'

import { verifySerialNumber } from '../../helper/BusinessRules'
import api from '../../services/api'

function Camera({ onChange, index }) {
  return (
    <div>
      <input
        required
        name="serial_number"
        minLength="10"
        maxLength="13"
        type="text"
        onChange={(e) => onChange(index, e)}
        placeholder="Número de série da câmera (BXXXXXXXXX, CIXXXXXXXXXXX ou BZXXXXXXXXXXX)*"
      />
      <select required name="model" onChange={(e) => onChange(index, e)}>
        <option selected>Selecione o Modelo da Câmera</option>
        <option value="119874">119874</option>
        <option value="119874R">119874R</option>
        <option value="119876">119876</option>
        <option value="119930B">119930B</option>
        <option value="119932C">119932C</option>
        <option value="119936C">119936C</option>
        <option value="119938C">119938C</option>
        <option value="119949C">119949C</option>
        <option value="119975C">119975C</option>
        <option value="119977C">119977C</option>
        <option value="119987C">119987C</option>
        <option value="119932M">119932M</option>
        <option value="119936M">119936M</option>
        <option value="119938M">119938M</option>
        <option value="119949M">119949M</option>
        <option value="119975M">119975M</option>
        <option value="119977M">119977M</option>
        <option value="119987M">119987M</option>
      </select>
      <input
        name="cameraNFE"
        type="text"
        onChange={(e) => onChange(index, e)}
        placeholder="Nota fiscal"
      />
      <textarea
        required
        name="description"
        onChange={(e) => onChange(index, e)}
        placeholder="Descrição dos defeitos*"
      ></textarea>
    </div>
  );
}

export default function Homepage({ history }) {
  const [formValue, setFormValue] = useState({profile: null})
  const [cameras, setCameras] = useState([{serial_number: '', model: '', description: ''}])
  const [numCameras, setNumCameras] = useState(1)
  const [sending, setSending] = useState(false)
  const [profile, setProfile] = useState('biologist')
  const [profileFinal, setProfileFinal] = useState(null)

  const handleChange = (e) => {
    let x = formValue
    x[e.target.name] = e.target.value

    if (e.target.name === 'profile') {
      if (e.target.value !== 'other' && e.target.value !== 'biologist') {
        x['profile_final'] = e.target.value
        setProfileFinal(e.target.value)
      }
      setProfile(e.target.value)
    } 
    if (e.target.name === 'profile_final') setProfileFinal(e.target.value)

    setFormValue(x)
  }

  const handleCameraChange = (i, e) => {
    let x = cameras
    x[i][e.target.name] = e.target.value
    setCameras(x)
  }

  const addCamera = () => {
    let x = cameras
    x.push({serial_number: '', model: '', description: ''})
    setCameras(x)
    setNumCameras(numCameras+1)
  }

  const removeCamera = () => {
    let x = cameras
    x.pop()
    setCameras(x)
    setNumCameras(numCameras-1)
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault()

    setSending(true)
    
    if (profileFinal == null) {
      alert('Por favor, insira um perfil para seu cadastro.')
      setSending(false)
      return
    }
    
    for (let index = 0; index < cameras.length; index++) {
      const element = cameras[index];

      if (!verifySerialNumber(element.serial_number)) {
        alert(`Por favor, insira um número de série válido para a câmera #${index + 1}. (Por exemplo: B123456789, CI12345678900 ou BZ12345678900)`)
        setSending(false)
        return
      }
    }

    let hasError = false

    for (let index = 0; index < cameras.length; index++) {
      const camera = cameras[index]

      const data = { ...formValue, ...camera }
      
      await api.post('/laudos/test', data)
      .then(response => response.data)
      .then(response => {
        if (response.error) {
          hasError = true
          alert(`[Câmera #${index+1}] ${response.error}`)
        }
      })

      if (hasError) break
    }

    for (let i = 0; i < cameras.length; i++) {
      const c1 = cameras[i]

      for (let j = 0; j < cameras.length; j++) {
        if (i === j) continue

        const c2 = cameras[j]
        
        if (c1.serial_number === c2.serial_number) {
          alert(`Erro: a câmera #${i+1} possui o mesmo número de série da câmera #${j+1}.`)
          hasError = true
        }

        if (hasError) break  
      }

      if (hasError) break
    }

    if (!hasError) {
      for (let index = 0; index < cameras.length; index++) {
        const camera = cameras[index];
  
        const data = { ...formValue, ...camera }
        
        await api.post('/laudos/new', data)
        .then(response => response.data)
        .then(response => {
          if (response.error) {
            alert(`[Câmera #${index+1}] ${response.error}`)
          }
        })
      }
      setSending(false)
      
      history.push('/sucesso')
    }

    setSending(false)
  }

  return (
    <Container>
      <Header>
        formulário de assistência técnica Bushnell
      </Header>
      <Paragraph>
        A LOG NATURE é distribuidora exclusiva da BUSHNELL no Brasil e única assistência técnica especializada e 
        autorizada de câmeras trap no país.
      </Paragraph>
      <Paragraph>
        Para continuar com seu pedido de assistência técnica, gentileza preencher o formulário abaixo descrevendo sua necessidade. Informamos que essa é uma fase inicial de triagem na qual verificaremos a possibilidade de reparo da câmera por meio dos dados inseridos. 
        Em caso de possibilidade de reparo, enviaremos um e-mail com um número da solicitação específico para cada câmera cadastrada.
      </Paragraph>
      <Paragraph>
        <b>Para maiores detalhes sobre as condições da assistência técnica, acesse o link a seguir: <Link to='condicoes'>Condições</Link>.</b>
      </Paragraph>
      <Paragraph>
        <b>Atenção! Se você adquiriu a câmera na LOG NATURE ou fornecedor autorizado e possui dúvidas sobre o período de garantia de sua câmera, favor verificar o status da cobertura no link a seguir: <Link to='verificar-garantia'>Verificar garantia</Link>.</b>
      </Paragraph>
      <Form method='post' onSubmit={handleSubmit}>
        <label>dados pessoais</label>
        <input type='text' required name='name' onChange={handleChange} placeholder='Nome*'/>
        <input type='email' required name='email' onChange={handleChange} placeholder='E-mail*'/>
        <input type='text' required name='telephone' onChange={handleChange} placeholder='Telefone*'/>
        <input type='text' name='institution' onChange={handleChange} placeholder='Instituição/Empresa'/>
        <select name='profile' onChange={handleChange} required placeholder='Perfil'>
          <option disabled defaultValue>Perfil*</option>
          <option value='biologist'>Biólogo</option>
          <option value='observer'>Observador/Interessado pela natureza</option>
          <option value='manager'>Setor de compras/Administrativo/Logística/Suprimentos</option>
          <option value='other'>Outro (digite abaixo)</option>
        </select>
        {profile === 'biologist' && (
          <select name='profile_final' onChange={handleChange} required placeholder='Especialização'>
            <option disabled selected>Cargo*</option>
            <option value='Setor Administrativo'>Setor Administrativo</option>
            <option value='Consultoria Ambiental'>Consultoria Ambiental</option>
            <option value='Órgão Público'>Órgão Público</option>
            <option value='Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa'>Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa</option>
            <option value='Aluno graduação'>Aluno graduação</option>
          </select>
        )}
        {profile === 'other' && (
          <input type='text' required name='profile_final' onChange={handleChange} placeholder='Digite o perfil em que mais se enquadra'/>
        )}

        <label>informações técnicas</label>
        
        {cameras.map((v,i) => <Camera key={i} index={i} value={v} onChange={handleCameraChange}/>)}

        <div style={{height: '100px'}}>
          <SubmitButton disabled={sending}>enviar</SubmitButton>
          <Button type='button' onClick={addCamera}>adicionar câmera</Button>
          {numCameras > 1 && (
            <Button type='button' onClick={removeCamera}>remover câmera</Button>
          )}
        </div>

      </Form>
    </Container>
  )
}