import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%
  }

  body {
    font: 14px 'Quicksand', sans-serif;
    background: #fff;
    color: #455761;
    -webkit-font-smoothing: antialiased !important;
  }

  ul, li {
    list-style: none;
  }

  a {
    font-weight: bold;
    text-decoration: none !important;
    color: #455761;
  }
`